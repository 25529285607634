const toggle = question => {
  if (!question) {
    return;
  }
  const shouldExpand = question.getAttribute('aria-expanded') !== 'true';
  const answer = document.getElementById(question.getAttribute('aria-controls'));
  question.setAttribute('aria-expanded', shouldExpand);
  answer.setAttribute('aria-hidden', !shouldExpand);
};

export const handler = (el, e) => {
  e.preventDefault();
  toggle(el);
  window.history.replaceState({}, '', el.hash);
  setTimeout(() => el.blur(), 0);
};

export const enhancer = el => {
  const { hash } = window.location;
  if (!hash) {
    return;
  }
  toggle(document.querySelector(`${hash} > a`));
  el.blur();
};
