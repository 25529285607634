import { debounce } from './util';

const DEBOUNCE = 300;
const PLACEHOLDER_CLASS = 'js-site-footer-placeholder';

const getElementHeight = el => el.offsetHeight;
const getViewportHeight = () => {
  return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
};
const getDocHeight = () => {
  return document.documentElement.getBoundingClientRect().height;
};

const getPlaceholder = () => document.querySelector(`.${PLACEHOLDER_CLASS}`);
const addOrUpdatePlaceholder = el => {
  const placeholder = getPlaceholder() || document.createElement('div');
  placeholder.style.height = `${getElementHeight(el)}px`;
  placeholder.classList.add(PLACEHOLDER_CLASS);
  el.parentNode.appendChild(placeholder);
};
const removePlaceholder = el => {
  const placeholder = getPlaceholder();
  if (!placeholder) {
    return;
  }
  placeholder.parentNode.removeChild(placeholder);
};

const adjustFooter = el => e => {
  if (
    // Viewport is smaller than footer height
    (getElementHeight(el) > getViewportHeight())
    // Document minus footer is smaller than viewport heigth
    || (getDocHeight() - getElementHeight(el) < getViewportHeight())
  ) {
    removePlaceholder(el);
    el.classList.remove('is-fixed');
    return;
  }
  addOrUpdatePlaceholder(el);
  el.classList.add('is-fixed');
};

export const enhancer = el => {
  adjustFooter(el)();
  window.addEventListener('resize', debounce(adjustFooter(el), DEBOUNCE));
  window.addEventListener('orientationchange', debounce(adjustFooter(el), DEBOUNCE));
};
