import { closest } from './util';

const toggle = el => {
  const shouldExpand = el.getAttribute('aria-expanded') !== 'true';
  const list = document.getElementById(el.getAttribute('aria-controls'));
  el.setAttribute('aria-expanded', shouldExpand);
  list.setAttribute('aria-hidden', !shouldExpand);
};

export const handler = (el, e) => {
  e.preventDefault();
  toggle(el);
  if (el.nextElementSibling) {
    // remove only button itself
    el.parentNode.removeChild(el);
  } else {
    // remove the whole button wrapper
    const wrapper = closest(el, '.button-set');
    wrapper.parentNode.removeChild(wrapper);
  }
};
