import { getCurrentBreakpoint, matchesBreakpoint } from './responsive';
import { register as scrollListener, isInView } from './scroll-listener';
import { debounce, uniqueId } from './util';

export const isOnCellular = () => {
  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
  if (!connection) {
    return false;
  }
  if (connection.type === 'cellular') {
    return true;
  }
  if (connection.effectiveType === 'slow-2g' || connection.effectiveType === '2g') {
    return true;
  }
  return false;
};

export const playVideo = video => {
  video.load();
  const play = () => {
    const playPromise = video.play();
    if (playPromise !== undefined) {
      playPromise.catch(error => console.log('Could not autoplay video:', video.src));
    }
    video.removeEventListener('canplaythrough', play, false);
  };
  if (video.readyState > 3) {
    play();
  }
  video.addEventListener('canplaythrough', play, false);
};

const handleScrolls = video => {
  const scrollHandler = e => {
    const visible = isInView(video);
    if (visible && video.paused) {
      video.play();
    } else if (!visible && !video.paused) {
      video.pause();
    }
  };
  scrollListener(`autoplay-video-${uniqueId()}`, debounce(scrollHandler, 60));
};

const appendVideo = el => {
  const size = getCurrentBreakpoint() === 'huge' ? 'large' : getCurrentBreakpoint();
  const video = document.createElement('video');
  if (!video.canPlayType('video/mp4')) {
    return;
  }

  video.setAttribute('src', el.getAttribute(`data-video-${size}`));
  video.setAttribute('poster', el.getAttribute(`data-poster-${size}`));
  video.setAttribute('autoplay', 'true');
  video.setAttribute('preload', 'auto');
  video.setAttribute('loop', '');
  video.setAttribute('muted', '');
  video.setAttribute('playsinline', '');
  video.setAttribute('webkit-playsinline', '');
  video.setAttribute('role', 'presentation');
  video.muted = true;

  if (!Modernizr.objectfit) {
    const images = el.parentNode.querySelectorAll('img');
    [...images].forEach(image => {
      image.parentNode.removeChild(image);
    });
  }
  el.parentNode.appendChild(video);

  playVideo(video);
  handleScrolls(video);
};

export const enhancer = el => {
  if (!matchesBreakpoint('small') || isOnCellular()) {
    return;
  }
  appendVideo(el);
};
