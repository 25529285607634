/* eslint-disable no-unused-vars */

import Flickity from 'flickity';

const ARROW = `M0 49.971l29.583 33.36c1.066 1.185 2.284 2.093 3.655 2.723 1.37.63 2.818.946 4.34.946 2.894 0 5.33-.955 7.31-2.866 1.98-1.91 2.97-4.26 2.97-7.05 0-1.529-.295-3-.885-4.414a11.706 11.706 0 0 0-2.541-3.783l-8.053-8.082h52.884c3.008 0 5.55-1.032 7.624-3.095C98.963 55.646 100 53.067 100 49.97c0-3.019-1.037-5.57-3.113-7.652-2.075-2.083-4.616-3.124-7.624-3.124H36.38l8.053-9.286c1.142-1.222 1.999-2.493 2.57-3.811.57-1.319.856-2.628.856-3.927 0-2.484-1.018-4.633-3.055-6.448C42.766 13.908 40.358 13 37.579 13c-1.561 0-3.037.325-4.427.974-1.39.65-2.579 1.548-3.569 2.694L0 49.971z`;

export const enhancer = el => {
  if (el.querySelectorAll('li').length < 2) {
    return;
  }

  const flkty = new Flickity(el, {
    cellAlign: 'center',
    cellSelector: 'li',
    contain: true,
    wrapAround: true,
    autoPlay: 7000,
    pageDots: true,
    prevNextButtons: true,
    arrowShape: ARROW,
  });
};
