/* eslint-disable no-unused-vars */

import Flickity from 'flickity';
import { matchesBreakpoint } from './responsive';

const SLIDER_SELECTOR = '.js-slider';
const ITEM_SELECTOR = '.js-slider-item';
const BG_SELECTOR = '.js-bg';

export const enhancer = el => {
  const slider = el.querySelector(SLIDER_SELECTOR);
  const items = el.querySelectorAll(ITEM_SELECTOR);
  const bg = el.querySelector(BG_SELECTOR);

  const flkty = new Flickity(slider, {
    cellAlign: matchesBreakpoint('small') ? 'center' : 'left',
    cellSelector: ITEM_SELECTOR,
    contain: true,
    wrapAround: false,
    freeScroll: true,
    pageDots: true,
    prevNextButtons: false,
  });

  const threshold = (matchesBreakpoint('small') ? 1 : 0.25) / items.length;
  flkty.on('scroll', progress => {
    if (progress > threshold) {
      el.classList.add('has-slided');
    } else {
      el.classList.remove('has-slided');
    }
  });

  flkty.on('select', index => {
    // Hide the 2nd last cell dot in CSS, and select the last  cell when 2nd last cell is
    // selected (desktop only). We do this because we show multiple cells at once, and
    // else the last two dots will compete being the current one.
    if (matchesBreakpoint('small') && (index === flkty.cells.length - 2)) {
      flkty.select(flkty.cells.length - 1);
    }
  });
};
