let isSubmitting;

const MERGE_FIELD_ID_PREFIX = 'mce-';

const buildUrlQuery = (data) => {
  return Object.keys(data).map((k) => {
    return `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`;
  }).join('&');
};

const getMergeFields = (form) => {
  const $fields = form.querySelectorAll('input, select, textarea');
  return [...$fields].filter((el) => el.id.indexOf(MERGE_FIELD_ID_PREFIX) === 0);
};

const redirectTo = (url) => {
  window.location.href = url;
};

const getMergeData = (form) => {
  return getMergeFields(form).reduce(
    (acc, cur) => {
      acc[cur.name] = cur.value;
      return acc;
    },
    {}
  );
};

const submit = (form) => {
  if (isSubmitting) {
    return;
  }
  isSubmitting = true;

  const $honey = form.querySelector('input[name="b_honey"]');
  if ($honey.value !== '') {
    return;
  }

  const $button = form.querySelector('button');
  const $error = form.querySelector('.js-error');
  const $email = form.querySelector('input[name="EMAIL"]');
  const listId = form.getAttribute('data-list-id');
  const interestId = form.getAttribute('data-interest-id');
  const successUrl = form.getAttribute('data-success-url');

  $button.classList.remove('is-completed');
  $button.classList.add('is-loading');
  $error.setAttribute('aria-hidden', 'true');
  $error.innerText = "Something went wrong. Try again.";

  const payload = {
    action: 'tff_mailchimp_signup',
    list_id: listId,
    email: $email.value,
    interest_id: interestId,
    merge_fields: JSON.stringify(getMergeData(form)),
  };

  fetch(window.ajax_url, {
    method: 'POST',
    body: buildUrlQuery(payload),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' },
    credentials: 'same-origin',
  }).then((response) => {
    return response.json();
  }).then((data) => {
    $button.classList.remove('is-loading');
    if (!data.success) {
      const error = JSON.parse(data.data.result.body);
      $error.innerText = error.title;
      $error.setAttribute('aria-hidden', 'false');
    } else {
      if (successUrl) {
        redirectTo(successUrl);
      }
      getMergeFields(form).map((el) => { el.value = ''; });
      $button.classList.add('is-completed');
    }
    isSubmitting = false;
  });
};

export const enhancer = (el) => {
  el.addEventListener('submit', (e) => {
    e.preventDefault();
    submit(el);
  });
};
