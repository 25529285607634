/* eslint-disable import/newline-after-import, import/first */

// Import libraries and polyfills
import 'classlist-polyfill';
import Promise from 'promise-polyfill';
if (typeof window.Promise === 'undefined') {
  window.Promise = Promise;
}
import 'whatwg-fetch';
import domready from 'domready';
import rafPolyfill from './polyfills/request-animation-frame';

// Handler and Enhancer utility
import handle from './modules/handle';
import enhance from './modules/enhance';

// Import functions that are executed on DOMready regardless of DOM
import { enhancer as scrollListener } from './modules/scroll-listener';
import { enhancer as responsive } from './modules/responsive';
import { default as disableHoverStylesOnScroll } from './modules/disable-hover-styles-on-scroll';

// Import handlers
import { handler as classToggler } from './modules/class-toggler';
import { handler as faqItemToggler } from './modules/faq';
import { handler as gtmEventHandler } from './modules/gtm-event';
import { handler as lazyMediaHandler } from './modules/lazy-media';
import { handler as listExpander } from './modules/list-expander';
import { toggleHandler as siteNavToggler } from './modules/site-nav';
import { overlayHandler as siteNavClose } from './modules/site-nav';
import { handler as socialShareButton } from './modules/social-share-button';
import { handler as scrollTo } from './modules/scroll-to';

// Import enhancers
import { enhancer as autoplayVideo } from './modules/autoplay-video';
import { enhancer as boldStatementSlider } from './modules/bold-statement-slider';
import { enhancer as councilMap } from './modules/council-map';
import { enhancer as faqList } from './modules/faq';
import { enhancer as gtmEventEnhancer } from './modules/gtm-event';
import { enhancer as imageSlider } from './modules/image-slider';
import { enhancer as logoStatsVideo } from './modules/logo-stats';
import { enhancer as mailchimpSignup } from './modules/mailchimp-signup';
import { enhancer as objectFit } from './modules/object-fit';
import { enhancer as parallaxBackground } from './modules/parallax-background';
import { enhancer as stickyHeader } from './modules/site-header';
import { enhancer as siteFooter } from './modules/site-footer';
import { colorChanger as siteHeaderColorChanger } from './modules/site-header';
import { enhancer as siteNav } from './modules/site-nav';

const executeOnReady = () => {
  disableHoverStylesOnScroll(); // Disable hover styles on scroll
  scrollListener(); // Initialise central scroll listener
  responsive(); // Set document width on resize and orientation change
  window.requestAnimationFrame = window.requestAnimationFrame || rafPolyfill;
};

function main() {
  executeOnReady();

  handle({
    classToggler,
    faqItemToggler,
    gtmEventHandler,
    lazyMediaHandler,
    listExpander,
    siteNavClose,
    siteNavToggler,
    socialShareButton,
    scrollTo,
  });

  enhance({
    autoplayVideo,
    boldStatementSlider,
    councilMap,
    faqList,
    imageSlider,
    gtmEventEnhancer,
    mailchimpSignup,
    logoStatsVideo,
    objectFit,
    parallaxBackground,
    siteFooter,
    siteNav,
    siteHeaderColorChanger,
    stickyHeader,
  });
}

domready(() => {
  main();
});
