export const handler = (el, e) => {
  const target = document.querySelector(el.hash);
  if (!target || typeof target.scrollIntoView === 'undefined') {
    return;
  }

  e.preventDefault();
  target.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  });
  el.blur();
};
