import Headroom from 'headroom.js';
import { throttle, uniqueId } from './util';
import { matchesBreakpoint } from './responsive';
import { register as registerScrollListener } from './scroll-listener';

const HEADER_SELECTOR = '.js-site-header';

const COLOR_OFFSET = 55;
const COLOR_ABOVE_ATTRIBUTE = 'data-site-header-color-above';
const COLOR_BELOW_ATTRIBUTE = 'data-site-header-color-below';

const getSiteHeaderElement = () => document.querySelector(HEADER_SELECTOR);

const setColor = (header, color) => {
  if (header.getAttribute('data-color') === color) {
    return;
  }
  header.setAttribute('data-color', color);
};

const getColorsFromElement = el => {
  return {
    above: el.getAttribute(COLOR_ABOVE_ATTRIBUTE),
    below: el.getAttribute(COLOR_BELOW_ATTRIBUTE),
  };
};

const getColor = (el, colors) => {
  return el.getBoundingClientRect().top > COLOR_OFFSET
    ? colors.above
    : colors.below;
};

export const colorChanger = el => {
  if (!matchesBreakpoint('small')) {
    return;
  }
  const header = getSiteHeaderElement();
  const colors = getColorsFromElement(el);
  const setColors = () => setColor(header, getColor(el, colors));
  registerScrollListener(`color-${uniqueId}`, throttle(setColors, 60));
  setColors();
};

export const enhancer = el => {
  const headroom = new Headroom(el, {
    offset: 30,
    tolerance: {
      down: 0,
      up: 10,
    },
    classes: {
      initial: 'is-loaded',
      pinned: 'is-pinned',
      unpinned: 'is-unpinned',
      top: 'is-at-top',
      notTop: 'is-not-at-top',
    },
  });
  headroom.init();
};
