import { matchesBreakpoint } from './responsive';
import { playVideo } from './autoplay-video';

export const enhancer = video => {
  if (!video.canPlayType('video/mp4')) {
    return;
  }
  const size = matchesBreakpoint('tiny') ? 'large' : 'small';
  video.poster = video.getAttribute(`data-poster-${size}`);
  video.src = video.getAttribute(`data-video-${size}`);
  playVideo(video);
};
